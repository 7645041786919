// productsStore.js
// productsStore.js
export const books = [
    {
        id: 1,
        title: 'کتاب آموزش React',
        image: 'https://images.manning.com/360/480/resize/book/4/dbe54e5-e0a2-43f0-a122-7d3ce329535c/Thomas-React-HI.png',
        price: '۱۵۰,۰۰۰',
        originalPrice: '۱۶۵,۰۰۰', // قیمت اصلی
        description: 'کتاب جامع آموزش React برای مبتدیان و حرفه‌ای‌ها.',
        discount: '۱۰', // درصد تخفیف بدون علامت درصد
        rating: 4.8,
        category: 'books',
        specialOffer: 'فروش ویژه', // یا "پیشنهاد شگفت‌انگیز" و ...
        customLabel: 'سفارشی',
    },
    {
        id: 2,
        title: 'کتاب آموزش JavaScript',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTBSYmeXzCx8pgJetfyJqdu3eDycOq95WgyvVT9GPIu-gVhwRVL7bRvmEuZtY8eroKQ_I&usqp=CAU',
        price: '۱۲۰,۰۰۰',
        originalPrice: '۱۳۰,۰۰۰',
        description: 'آموزش جامع JavaScript از مبتدی تا پیشرفته.',
        discount: '۸',
        rating: 4.6,
        category: 'books',
        specialOffer: 'پیشنهاد شگفت‌انگیز',
        customLabel: 'سفارشی',
    },
    // سایر کتاب‌ها
];


export const articles = [
    {
        id: 3,
        title: 'مقاله بهینه‌سازی وب',
        image: 'https://m.media-amazon.com/images/I/412PLr9m4VL._SL500_.jpg',
        price: '۲۰,۰۰۰',
        description: 'مقاله‌ای در مورد تکنیک‌های بهینه‌سازی وب‌سایت.',
        discount: '5',
        rating: 4.5,
        category: 'articles',
    },
    {
        id: 4,
        title: 'مقاله سئو',
        image: 'https://m.media-amazon.com/images/I/412PLr9m4VL._SL500_.jpg',
        price: '۱۵,۰۰۰',
        description: 'نکات و تکنیک‌های بهبود سئو وب‌سایت.',
        discount: '10',
        rating: 4.7,
        category: 'articles',
    },
    // سایر مقالات
];

export const videos = [
    {
        id: 5,
        title: 'دوره ویدیویی آموزش Node.js',
        image: 'https://m.media-amazon.com/images/I/412PLr9m4VL._SL500_.jpg',
        price: '۲۰۰,۰۰۰',
        description: 'دوره کامل آموزش Node.js از مقدماتی تا پیشرفته.',
        discount: '15',
        rating: 4.9,
        category: 'videos',
        specialSale: 'فروش ویژه',
        customBadge: 'سفارشی'
    },
    {
        id: 6,
        title: 'دوره ویدیویی آموزش Python',
        image: 'https://m.media-amazon.com/images/I/412PLr9m4VL._SL500_.jpg',
        price: '۱۸۰,۰۰۰',
        description: 'آموزش پروژه‌محور زبان برنامه‌نویسی Python.',
        discount: '20',
        rating: 4.8,
        category: 'videos',
    },
    // سایر ویدیوها
];

export const allProducts = [...books, ...articles, ...videos];

export const categories = [
    {
        id: 'all',
        name: 'همه محصولات',
    },
    {
        id: 'books',
        name: 'کتاب‌ها',
    },
    {
        id: 'articles',
        name: 'مقالات',
    },
    {
        id: 'videos',
        name: 'ویدیوها',
    },
];
