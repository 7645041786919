import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Rate, Button, Badge, Dropdown, Menu } from 'antd';
import { SearchOutlined, ShoppingCartOutlined, EllipsisOutlined } from '@ant-design/icons';
import HeaderShop from '../HeaderShop/HeaderShop';
import { allProducts } from '../../../productsStore';
import './ProductDetail.css';

const ProductDetail = () => {
    const { productId } = useParams();
    const navigate = useNavigate();
    const product = allProducts.find((p) => p.id === parseInt(productId));
    const [searchVisible, setSearchVisible] = useState(false);

    if (!product) return <p>محصول یافت نشد</p>;

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => navigate('/store')}>بازگشت به فروشگاه</Menu.Item>
        </Menu>
    );

    return (
        <>
            <HeaderShop products={allProducts} />

            {/* در حالت موبایل */}
            <div className="header-mobile">
                <SearchOutlined className="header-icon" onClick={() => setSearchVisible(!searchVisible)} />
                <Badge count={5} className="header-icon">
                    <ShoppingCartOutlined />
                </Badge>
                <Dropdown overlay={menu} trigger={['click']}>
                    <EllipsisOutlined className="header-icon" />
                </Dropdown>
            </div>

            <div className="product-detail">
                <div className="product-detail-left">
                    <Card className="price-card">
                        <p>فروشنده:</p>
                        <p>{product.sellerName}</p>
                        <p>درصد رضایت: {product.satisfactionRate}% (عملکرد عالی)</p>
                        <p>قیمت: {product.price} تومان</p>
                        <p>تخفیف: {product.discount}%</p>
                        <p>تعداد خرید: بیش از {product.purchaseCount} نفر</p>
                        <p>گارانتی: {product.warranty}</p>
                        <p>ارسال توسط: {product.shipping}</p>
                        <Button type="primary" className="add-to-cart-button">افزودن به سبد خرید</Button>
                    </Card>
                </div>
                <div className="product-detail-center">
                    <h2>{product.title}</h2>
                    <p>{product.description}</p>
                    <Rate disabled defaultValue={product.rating} />
                    <div className="product-features">
                        {product.features && product.features.map((feature, index) => (
                            <p key={index}>{feature}</p>
                        ))}
                    </div>
                </div>
                <div className="product-detail-right">
                    <img alt={product.title} src={product.image} className="main-image" />
                    <div className="thumbnail-images">
                        {product.images && product.images.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={product.title}
                                className="thumbnail"
                                onClick={() => {
                                    document.querySelector('.main-image').src = image;
                                }}
                            />
                        ))}
                    </div>
                </div>
            </div>

            {/* فوتر موبایل */}
            <div className="mobile-footer">
                <span className="mobile-price">{product.price} تومان</span>
                <Button type="primary" className="add-to-cart-button-mobile">افزودن به سبد خرید</Button>
            </div>

            <hr />
            <div className="product-detail-menu">
                <button>معرفی</button>
                <button>بررسی</button>
                <button>دیدگاه‌ها</button>
                <button>پرسش‌ها</button>
            </div>
            <hr />
            <div className="product-suggestions">
                <h3>پیشنهادات</h3>
                <div className="suggestion-list">
                    {allProducts.slice(0, 5).map((suggestedProduct) => (
                        <div key={suggestedProduct.id} className="suggestion-item">
                            <img src={suggestedProduct.image} alt={suggestedProduct.title} />
                            <p>{suggestedProduct.title}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ProductDetail;
