import React from 'react';
import './ProductList.css';
import './../../Footer/Footer.css'; // فرض می‌کنیم که این فایل شامل فونت Digikala است
import persianJs from 'persianjs';
import { TbSpeakerphone } from "react-icons/tb";
import { allProducts } from '../../../productsStore'; // اطمینان از وارد کردن محصولات

const ProductList = ({ products = allProducts, onItemClick }) => {
    return (
        <div className="product-container">
            <div className="product-table">
                {products.map((product) => (
                    <div
                        key={product.id}
                        className={`product-cell ${!product.discount ? 'no-discount' : ''}`}
                        onClick={() => onItemClick(product)}
                    >
                        <div className="product-badge">
                            {product.customLabel && (
                                <div className="custom-badge-left">
                                    <TbSpeakerphone className="custom-icon-left" />
                                    <span className="custom-label">{product.customLabel}</span>
                                </div>
                            )}
                            {product.specialOffer && (
                                <span className="special-sale">{product.specialOffer}</span>
                            )}
                        </div>
                        <img src={product.image} alt={product.title} className="product-image" />
                        <div className="product-info">
                            <h3>
                                {product.title} - <span className="description">{product.description}</span>
                            </h3>
                            <div className="product-rating">
                                <i className="fa fa-star" /> {/* ستاره برای ریتینگ */}
                                <span>{persianJs(product.rating.toFixed(1)).englishNumber().toString()}</span>
                            </div>
                        </div>
                        <br />
                        <br />
                        <p className="product-price">
                            {product.discount && (
                                <span className="discount-label">
                                    {persianJs(product.discount).englishNumber().toString()}%
                                </span>
                            )}
                            <span className="price-discount-container">
                                <span className="price">
                                    {persianJs(product.price).englishNumber().toString()} تومان
                                </span>
                                {product.originalPrice && (
                                    <span className="original-price">
                                        {persianJs(product.originalPrice).englishNumber().toString()} تومان
                                    </span>
                                )}
                            </span>
                        </p>
                    </div>
                ))}
            </div>
            <div className="filter-container">
                {/* محتوای کامپوننت فیلتر */}
            </div>
        </div>
    );
};

export default ProductList;
